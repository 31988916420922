import type {
  GroupDocumentWithSubscription,
  GroupDocument,
  ReleaseDocument,
} from '@og-shared/types';
import { getTodayString, keys } from '@og-shared/utils';
import { Capacitor } from '@capacitor/core';
import { isDemoGroupId } from '@og-shared/utils';

import { getSavedAttribution } from '../../global/attribution-utils';
import { getGroupUrl } from '../../global/get-group-url';
import { routeInApp } from '../../global/route-in-app';
import { createNewUserAndGroup } from '../../global/functions';
import { showPermissionAlert } from '../../global/show-missing-permission-alert';
import { setStorageKey, StorageKeys } from '../../global/storage';
import { initNativePurchases } from '../../global/subscribe-utils';
import { translate } from '../../global/translate';
import {
  AnalyticsEvent,
  trackAnalyticsEvent,
  trackGroup,
} from '../../services/analytics';
import { firestoreUpdateGroup } from '../../services/firestore/groups';
import { SPENDING_BY_TYPE_ID } from '../../services/static-data';
import { authState } from '../auth.store';
import { groupState } from '../group.store';
import { setFeaturesFromGroup } from '../ui.store';
import { isMemberOfGroup, refreshCustomClaims } from './auth';
import { showNewReleasesModal } from './releases';
import { getUserDocument, changeGroup } from './user';

export const setGroup = async (
  groupDoc: GroupDocumentWithSubscription | null
) => {
  if (!groupDoc || !groupDoc.group_id) return showPermissionAlert();
  logPurchaseAnalytics(groupDoc);

  // UI
  setFeaturesFromGroup(groupDoc);

  // GROUP
  let one_goal_set = false;
  let invite_members = false;
  let categorize = false;
  if (groupDoc && groupDoc.one_goal_id) {
    one_goal_set = true;
  }
  if (groupDoc && groupDoc.user_count > 1) {
    invite_members = true;
  }
  if (groupDoc && groupDoc.onboarding && groupDoc.onboarding.invite_members) {
    invite_members = true;
  }
  if (groupDoc && groupDoc.onboarding && groupDoc.onboarding.categorize) {
    categorize = true;
  }

  const newGroupDoc: GroupState['groupDoc'] = {
    auto_fill: groupDoc.auto_fill,
    budget_view_id: groupDoc.budget_view_id ?? SPENDING_BY_TYPE_ID,
    group_id: groupDoc.group_id,
    funding_per_year: groupDoc.funding_per_year,
    funding_days: groupDoc.funding_days,
    name: groupDoc.name,
    one_goal_id: groupDoc.one_goal_id,
    subscription: groupDoc.subscription,
    goal: groupDoc.goal,
    expires: groupDoc.expires,
    status: groupDoc.status,
    invited_by_uid: groupDoc.invited_by_uid || null,
    js_version: groupDoc.js_version,
    last_activity: groupDoc.last_activity,
    unread_messages: groupDoc.unread_messages,
    flagged_for_deletion_on: groupDoc.flagged_for_deletion_on,
    partner_id: groupDoc.partner_id,
    suggestion_id: groupDoc.suggestion_id,
    paid_via: groupDoc.paid_via,
    created_by_uid: groupDoc.created_by_uid,
    analyze_spending: groupDoc.analyze_spending,
    shared_data: groupDoc.shared_data ?? {},
    entitlements: {
      plaid: false,
      cash_flow: false,
      transactions: false,
      max_item_count: 7,
      ...(groupDoc.entitlements ?? {}),
    },
    onboarding: {
      one_goal_set,
      invite_members,
      categorize,
    },
    user_count: groupDoc.user_count,
    groupLoaded: true,
    dashboard: groupDoc.dashboard ?? [],
    linking_accounts: groupDoc.linking_accounts ?? false,
  };

  await saveActiveGroupId(groupState, groupDoc);
  await saveDefaultHomePage(
    groupState.groupDoc.entitlements.transactions,
    groupDoc.entitlements?.transactions ?? false
  );

  if (JSON.stringify(newGroupDoc) !== JSON.stringify(groupState.groupDoc)) {
    // group doc has changed in a way that affects state
    groupState.groupDoc = newGroupDoc;
  }
  const todayString = getTodayString();
  updateLastActivity(todayString);
  redirectFromGroupDoc({
    admin: authState.userInfo.admin,
    groupDoc,
  });
  if (!Capacitor.isNativePlatform()) return;
  await initNativePurchases(groupDoc.group_id);
};

function saveActiveGroupId(
  oldGroup: GroupState,
  newGroup: GroupDocumentWithSubscription
) {
  if (oldGroup.groupDoc.group_id === newGroup.group_id) return;
  if (
    // do this after group_id check - so we don't call this api on every group doc change
    newGroup &&
    isMemberOfGroup(authState.userInfo.groupPermissions, newGroup.group_id)
    // don't link to groups if admin
  ) {
    trackGroup(newGroup);
  }
  return setStorageKey(StorageKeys.og_active_group_id, newGroup.group_id);
}

function saveDefaultHomePage(
  transactionsEntitlementOld: boolean,
  transactionsEntitlementNew: boolean
) {
  if (transactionsEntitlementOld === transactionsEntitlementNew) {
    return;
  }
  const homePage = transactionsEntitlementNew ? '/budgets' : '/plan';
  return setStorageKey(StorageKeys.og_home_page, homePage);
}

export function updateLastActivity(today: string) {
  if (!today) return;
  if (groupState.groupDoc.last_activity === today) return;
  const group_id = groupState.groupDoc.group_id;
  if (!group_id) return;
  if (!authState.userInfo.groupPermissions[group_id]) return;
  // don't log last activity for admins
  // don't log activity for demo groups
  return updateGroup({ group_id, last_activity: today });
}

function redirectFromGroupDoc(props: {
  admin: boolean;
  groupDoc: GroupDocument;
}) {
  const { admin, groupDoc } = props;
  if (admin) return;
  if (groupDoc.analyze_spending !== 'started') {
    return;
  }
  // if they have started - we want to always send them to analyze
  // once it's finished. We'll redirect to success page
  routeInApp(getGroupUrl(groupDoc.group_id, '/intro/analyze-spending'));
}

export const updateGroup = (
  groupUpdate: Partial<GroupDocument> & { group_id: string }
) => {
  return firestoreUpdateGroup(groupUpdate.group_id, groupUpdate, undefined);
};

export const createNewGroup = async () => {
  try {
    const attribution = await getSavedAttribution();
    const groupId = await createNewUserAndGroup(attribution);
    await refreshCustomClaims();
    if (authState.userInfo.uid) {
      await getUserDocument(authState.userInfo.uid);
    }
    return groupId;
  } catch (error) {
    return console.error(error);
  }
};

export const groupDemoView = async (demo_group_id: string) => {
  let lastGroupId = groupState.lastGroupId;
  if (!isDemoGroupId(groupState.groupDoc.group_id)) {
    lastGroupId = groupState.groupDoc.group_id;
  }
  groupState.lastGroupId = lastGroupId;
  const release: ReleaseDocument = {
    date: '' as any,
    env: [],
    text: {
      en: translate('NOW_ENTERING_DEMO'),
      es: translate('NOW_ENTERING_DEMO'),
    },
    platform: [],
    version: 'DEMO',
    version_number: 0,
  };
  trackAnalyticsEvent(AnalyticsEvent.demo_mode_viewed);
  await showNewReleasesModal([release]);
  changeGroup(demo_group_id);
};

export const groupDemoExit = () => {
  let group_id = groupState.lastGroupId;
  if (!group_id) {
    group_id = keys(authState.userInfo.groupPermissions)[0];
  }
  if (!group_id) return;
  changeGroup(group_id);
};

function logPurchaseAnalytics(groupDoc: GroupDocumentWithSubscription) {
  if (
    !groupState.groupDoc.groupLoaded || // first load - not purchasing
    groupState.groupDoc.subscription || // already subscribed - already tracked skip
    !groupDoc ||
    !groupDoc.subscription ||
    !groupDoc.subscription.subscription ||
    !groupDoc.subscription.subscription.items ||
    !groupDoc.subscription.subscription.items.data ||
    !groupDoc.subscription.subscription.items.data[0] ||
    !groupDoc.subscription.subscription.items.data[0].plan
  ) {
    return;
  }
  try {
    const subscription = groupDoc.subscription;
    const source: 'website' = 'website';
    const plan = subscription.subscription.items.data[0].plan;
    // @todo would I ever have more than one item?
    const params = {
      value: plan.amount ?? 0 / 100,
      currency: plan.currency,
      purchase_id: subscription.subscription.id,
      source,
      price: `${plan.amount ?? 0 / 100}`,
      // tax: plan.tax_percent,
      items: subscription.subscription.items.data.map(item => {
        return {
          id: item.id,
          name: item.plan.nickname,
        };
      }),
      // coupon: plan.coupon
    };

    trackAnalyticsEvent(AnalyticsEvent.product_purchased, params);
  } catch (err) {
    console.error('error logging analytics for purchase');
  }
}

export function saveFirstTransactionCategorized() {
  if (groupState.groupDoc.onboarding.categorize) return;
  if (!groupState.groupDoc.group_id) return;
  updateGroup({
    group_id: groupState.groupDoc.group_id,
    onboarding: { categorize: getTodayString() },
  });
}
